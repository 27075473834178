@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #1c1c1c;
}
@font-face {
  font-family: 'Jost';
  src: url('./fonts/Jost-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Outfit';
  src: url('./fonts/Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Onest';
  src: url('./fonts/Onest-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.custom-button {
  padding: 12px 24px;
  border-radius: 50px;
  font-family: 'Outfit', sans-serif;
  font-weight: medium;
  color: #f5f5f5;
  background: linear-gradient(to right, #B79843, #82620F);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background: transparent;
  border-color: #B79843;
  border-width: 1px;
}

.icon {
  width: 20px;
}

